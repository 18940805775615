import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
/* import Skills from './components/Skills'; */
import Projects from './components/Projects';
import Contact from './components/Contact';
import ParticlesBackground from './components/ParticlesBackground';

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      {/* <Skills /> */}
      <Projects />
      <Contact />
      <ParticlesBackground />
    </div>
  );
}

export default App;
