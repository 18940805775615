import { configureStore } from '@reduxjs/toolkit';
import languageReducer from '../features/languageSlice'; // Imports the reducer function generated by the languageSlice slice

export default configureStore({
  // The 'reducer' key specifies the root reducer for the store
  // The root reducer is an object where each key corresponds to a slice of the store's state, and the value is the reducer function that manages that slice
  reducer: {
    language: languageReducer, // State managed by languageReducer will be stored under the language key
  },
});
