import React from 'react';
import '../index.css';
import { useSelector } from 'react-redux';

const Contact = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage); // Extracts language from the Redux store state and assigning it to the selectedLanguage variable for use within the component

  return (
    <div
      name="contact"
      className="w-full bg-[#0a192f] pt-20 flex flex-col justify-center md:h-screen"
    >
      <div className="max-w-[1100px] mx-auto flex flex-col justify-center p-4 w-full">
        <div className="z-10 pb-8">
          <h2 className="inline text-4xl font-bold text-gray-300 border-b-4 border-pink-600">
            {selectedLanguage === 'english' ? 'Contact' : 'Kontakt'}
          </h2>
          <p className="py-6 text-gray-300">
            {selectedLanguage === 'english'
              ? 'Submit the form below or send me an email at '
              : 'Fyll i formuläret nedan eller skicka mig ett mail på '}
            <a
              className="duration-500 ease-in-out transition-color hover:text-pink-600 email-link"
              href="mailto:igor.gryp@gmail.com"
            >
              igor.gryp@gmail.com
            </a>
          </p>
        </div>
        {/* Contact form */}
        <form
          method="POST"
          action="https://getform.io/f/7e286bac-1fa1-4887-9ab0-88e628b0e4c2"
          className="flex flex-col justify-center w-full max-w-[600px] mx-auto z-10"
        >
          <input
            className="bg-[#ccd6f6] p-2"
            type="text"
            placeholder={selectedLanguage === 'english' ? 'Name' : 'Namn'}
            name="name"
          ></input>
          <input
            className="bg-[#ccd6f6] my-4 p-2"
            type="email"
            placeholder={selectedLanguage === 'english' ? 'Email' : 'E-post'}
            name="email"
          ></input>
          <textarea
            className="bg-[#ccd6f6] p-2"
            name="message"
            rows="10"
            placeholder={selectedLanguage === 'english' ? 'Message' : 'Meddelande'}
          ></textarea>

          <button className="bg-[#0a192f] flex items-center justify-center px-4 py-3 mx-auto my-8 text-white transition duration-300 border-2  w-44 hover:bg-pink-600 hover:border-pink-600">
            {selectedLanguage === 'english' ? 'Submit' : 'Skicka'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
