import { createSlice } from '@reduxjs/toolkit';

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    selectedLanguage: 'english', // Default language
  },
  reducers: {
    // toggles between 'english' and 'swedish' when dispatched
    toggleLanguage(state) {
      state.selectedLanguage = state.selectedLanguage === 'english' ? 'swedish' : 'english';
    },
  },
});

export const { toggleLanguage } = languageSlice.actions; // Exports the action creator toggleLanguage
export default languageSlice.reducer; // Exports the reducer

// Reducer: A reducer is a pure function that specifies how the state changes in response to dispatched actions
// Action Creators: Action creators are functions that create action objects to be dispatched. A slice typically includes action creators that correspond to the actions that can be dispatched to update the slice's state
